<template>
    <div class="md:-mt-3">
        <loading-modal :isShow="isLoading"/>
        <div v-if="isLoading == false">
            <div>
                <div class="hero px-4 pt-4">
                    <div class="bg-white w-full rounded-2xl p-3 shadow-lg flex flex-col justify-center">
                        <h2 class="text-center text-md font-semibold text-gray-600 uppercase font-custom-1">PEMBAHASAN</h2>
                        <h2 class="text-center text-2xl font-semibold text-gray-800 uppercase font-custom-1">{{ materi_konten.judul_konten }}</h2>
                        <p class="text-center text-md text-gray-600">Nilai : {{ skor.nilai_akhir }}</p>
                    </div>
                </div>
            </div>


            <div class="my-3 mb-6 px-4 ">

                <div class="border-blue-500 border-2 mb-4 rounded-lg px-2 py-2" v-for="(soal, index_soal) in daftar_soal" :key="index_soal">

                    <div class="mb-2">
                        <div class="bg-gray-200 px-2 py-1 w-auto inline-block rounded" v-if="soal.nama_bab != null ">
                            <div class="font-semibold text-gray-800 text-sm">{{ soal.nama_bab }}</div>
                        </div>
                    </div>

                    <div class="mb-2 border-b-2 flex space-x-2 items-center pb-2">
                        <div class="font-bold flex-none">Nomor Soal</div>
                        <div class="flex-grow">
                            <div class="rounded-full bg-blue-400 w-6 h-6 font-semibold text-white items-center flex align-middle mr-2">
                                <div class="mx-auto">{{ soal.urutan }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-2">
                        <div class="mb-4" v-html="soal.pertanyaan"></div>
                    </div>

                    <div class="opsi bg-white px-2 py-2 rounded-lg  border-2 mb-3">
                        <div class="w-full text-sm md:text-base">
                            <div class="flex space-x-1 mb-1 font-semibold">
                                <div class="p-0.5 flex-grow bg-blue-300 px-2 py-0.5 rounded">
                                    Pilihan Jawaban
                                </div>
                                <div class="p-0.5 bg-blue-300 px-2 py-0.5 rounded text-center" style="width: 80px;">
                                    Point
                                </div>
                            </div>

                            <div class="flex space-x-1 mb-1" v-for="(opsi, index_opsi) in soal.opsi" :key="opsi.id_kuis_soal_opsi">
                                <div class="flex-grow px-2 py-1 rounded flex space-x-1" 
                                    :class="
                                            opsi.id_kuis_soal_opsi == soal.id_jawaban_opsi_user ?
                                                (
                                                    soal.id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-red-100'
                                                )
                                            : 
                                                (
                                                    soal.id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-gray-100'
                                                )
                                    ">
                                    <div class="h-6 w-6 rounded-md flex-none text-center flex items-center"
                                        :class="
                                            opsi.id_kuis_soal_opsi == soal.id_jawaban_opsi_user ?
                                                (
                                                    soal.id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-300' : 'bg-red-300'
                                                )
                                            : 
                                                (
                                                    soal.id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-300' : 'bg-gray-300'
                                                )

                                        "
                                    >
                                        <div class="text-center mx-auto">{{ option_list[index_opsi] }}</div>
                                    </div>
                                    <div v-html="opsi.isi"></div>
                                </div>

                                <div style="width: 80px;" class="flex-none px-2 py-1 rounded text-center"
                                        :class="
                                            opsi.id_kuis_soal_opsi == soal.id_jawaban_opsi_user ?
                                                (
                                                    soal.id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-red-100'
                                                )
                                            :
                                                (
                                                    soal.id_opsi_tertinggi_akhir == opsi.id_kuis_soal_opsi ? 'bg-green-100' : 'bg-gray-100'
                                                )

                                    "
                                    >{{ Number(opsi.bobot) }}
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
                

            </div>
            
        </div>
   </div>
</template>

<script>
    import LoadingModal from '../../components/LoadingModal.vue';
    import { errorHandler, isMobile, showErrorNotif } from '../../helpers/Tools';
    import moment from 'moment'

    export default {
        name: 'QuizReviewDetail',
        data(){
            return {
                isLoading    : true,
                daftar_soal  : null,
                materi_konten: null,
                option_list  : ['A', 'B', 'C', 'D', 'E'],
            }
        },
        components : {
            LoadingModal
        },
        mounted() {
			this.getPembasahan();
		},
		methods : {
            isMobile() {
                return isMobile();
            },
            async getPembasahan() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getPembahasanPerKuisMateri", {
                        id_materi_user_skor: this.$route.params.id_materi_user_skor,
                    });
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.daftar_soal   = result.data.daftar_soal;
                        this.materi_konten = result.data.materi_konten;
                        this.skor          = result.data.skor;
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },
           
        }

       
    }
</script>
<style>
    .pembahasan img, .pembahasan iframe {
        max-width: 100%;
    }
</style>
